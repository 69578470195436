import React from "react";

export default function Card({ service }) {
  return (
    <a
      className="card"
      style={{ "--src": `url(/imgs/${service.img})` }}
      href={"#"}
      target="_self"
    >
      <h1>{service.title}</h1>

      <ol className="main-body">
        {service.desciption.map((line, index) => (
          <li key={index}>{line}</li>
        ))}
      </ol>
    </a>
  );
}
