import React from "react";
import Card from "./UI/Card";
import "../styles/services.css"

const services = [
  {
    title: "ÉCHAFAUDAGE ET ÉTAIEMENT",
    desciption: [
      "Relevé sur site",
      "Plans de montage",
      "Note de calcul",
      "Assistance technique",
      "Examens réglementaires",
      "Formation accompagnement personnalisé",
    ],
    img: "service_1.jpg",
  },
  {
    title: "BET STRUCTURES",
    desciption: [
      "Relevé sur site",
      "Conception de structure",
      "Notes de calcul",
      "Plans d'ensemble",
      "Dossier de fabrication",
      "Accompagnement technique jusqu'à la réception des travaux",
    ],
    img: "service_2.jpg",
  },
  {
    title: "AMO - MOE",
    desciption: [
      "Définition de cahier des charges",
      "Étude avant-projet sommaire (APS)",
      "Étude avant-projet définitif (APD)",
      "Établissement des permis de construire",
      "Élaboration des pièces écrites (Pro DCE)",
      "Planification et suivi des travaux d’exécution",
      "Réception des travaux jusqu'au parfait achèvement",
    ],
    img: "service_3.jpg",
  },
];

export default function Services() {
  return (
    <main className="services" id="service">
      <div className="services-desc">
        <h3>Le bureau d'études techniques ATECH</h3>
        <h1>Nous pouvons intervenir à différentes étapes de votre projet</h1>
      </div>

      <div className="services-cards">
        {services.map((service, index) => (
          <Card service={service} key={index} />
        ))}
      </div>
    </main>
  );
}
