import React from "react";

export default function Slider() {
  const logos = [
    "logo_1.png",
    "logo_2.png",
    "logo_3.png",
    "logo_4.png",
    "logo_5.png",
    "logo_6.png",
    "logo_7.png",
    "logo_8.png",
    "logo_9.png",
    "logo_10.png",
  ];
  return (
    <div className="logos-slide">
      {logos.map((logo, index) => (
        <img src={`/imgs/${logo}`} key={index} alt="collaborater logo" />
      ))}
    </div>
  );
}
