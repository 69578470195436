import React from "react";
import "../styles/collaboraters.css";
import Slider from "./UI/Slider";

export default function Collaboraters() {
  return (
    <main className="collaboraters" id="collaborateurs">
      <p>Les entreprises avec lesquelles nous collaborons</p>
      <h1>
        Ils nous font "<span>confiance</span>"
      </h1>

      <div className="logos">
        <Slider />
        <Slider />
      </div>
    </main>
  );
}
