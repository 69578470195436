import { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import Services from "./components/Services";
import About from "./components/About";
import Background from "./components/Background";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Preloader from "./components/Preloader";
import Process from "./components/Process";
import Collaboraters from "./components/Collaboraters";
import Footer from "./components/Footer";
import { isMobile } from "react-device-detect";
import References from "./components/References";
import Lenis from "@studio-freight/lenis";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } else {
      window.addEventListener("load", function () {
        setLoading(false);
      });
    }
  }, [isMobile]);

  // useEffect(() => {
  //   const lenis = new Lenis();

  //   const raf = (time) => {
  //     lenis.raf(time);
  //     requestAnimationFrame(raf);
  //   };

  //   requestAnimationFrame(raf);
  // }, []);

  return (
    <>
      <AnimatePresence mode="wait">{loading && <Preloader />}</AnimatePresence>
      <Background />
      <Header />
      <Hero />
      <Services />
      <About />
      <Process />
      <References />
      <Collaboraters />
      <Footer />
    </>
  );
}

export default App;
