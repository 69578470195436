export const Menu = () => (
  <svg
    width={20}
    height={20}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    viewBox="0 0 24 24"
  >
    <path d="M3 12h18" />
    <path d="M3 6h18" />
    <path d="M3 18h18" />
  </svg>
);

export const Xmark = () => (
  <svg
    width={20}
    height={20}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    viewBox="0 0 24 24"
  >
    <path d="M18 6 6 18" />
    <path d="m6 6 12 12" />
  </svg>
);

export const LinkedIn = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 35 35"
  >
    <path
      fill="currentColor"
      d="M7.834 35H.578V11.632h7.256V35zM4.202 8.445C1.882 8.445 0 6.523 0 4.202a4.202 4.202 0 118.405 0c0 2.32-1.883 4.243-4.203 4.243zM34.992 35h-7.24V23.625c0-2.711-.055-6.188-3.773-6.188-3.773 0-4.35 2.945-4.35 5.992V35h-7.25V11.632h6.96v3.188h.102c.968-1.836 3.335-3.774 6.865-3.774 7.344 0 8.694 4.836 8.694 11.118V35h-.008z"
    ></path>
  </svg>
);
export const ArrowTopRight = () => (
  <svg
    width={15}
    height={15}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    viewBox="0 0 24 24"
  >
    <path d="M7 17 17 7" />
    <path d="M7 7h10v10" />
  </svg>
);
