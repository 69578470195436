import { motion } from "framer-motion";
import { useRef, useState } from "react";

export default function Button({ content, ...props }) {
  const buttonRef = useRef();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const onMouseMove = ({ offsetX, offsetY }) => {
    let { height, width } = buttonRef.current.getBoundingClientRect();

    let centerY = height / 2;
    let centerX = width / 2;

    let x = (offsetX - centerX) / 3;
    let y = (offsetY - centerY) / 3;

    setPosition({ x, y });
  };

  const onMouseOut = () => {
    setPosition({ x: 0, y: 0 });
  };
  return (
    <motion.a
      {...props}
      ref={buttonRef}
      initial={{ y: 0, x: 0 }}
      animate={{ y: position.y, x: position.x }}
      transition={{ duration: 0.1 }}
      onMouseMove={({ nativeEvent: { offsetX, offsetY } }) =>
        onMouseMove({ offsetX, offsetY })
      }
      onMouseOut={onMouseOut}
    >
      {content}
    </motion.a>
  );
}
