import React from 'react'

export default function ProcessItem({ step }) {
  return (
    <div className="process-item">
        <div className="process-line">
        <div className="line"></div>
        <img src={`/imgs/${step.img}` } alt="procces item" />
        <div className="line"></div>
        </div>
        <div className="process-info">
        <h2>{step.title}</h2>
        <ul>
            {step.description.map((ele, index) => <li key={index}>{ele}</li>)}
        </ul>
        </div>
    </div>
  )
}
