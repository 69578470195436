import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "../styles/preloader.css"

export default function Preloader() {
  const [windowDimentions, setWindowDimentions] = useState({ w: 0, h: 0 });

  useEffect(() => {
    function resize() {
      setWindowDimentions({ w: window.innerWidth, h: window.innerHeight });
    }
      let scrollTop = document.documentElement.scrollTop;
      let scrollLeft = document.documentElement.scrollLeft;

    function scroll () {
      window.scrollTo(scrollLeft, scrollTop);
    }
    resize();
    window.addEventListener("resize", resize);
    window.addEventListener('scroll', scroll)
    
    return () => {
      window.removeEventListener('scroll', scroll)
      window.removeEventListener("resize", resize);
    };
  }, []);

  const initialPath = `M 0 0 C 0 ${windowDimentions.h / 4}, ${
    windowDimentions.w
  } ${windowDimentions.h / 4}, ${windowDimentions.w} 0`;
  const exitPath = `M 0 0 C 0 0, ${windowDimentions.w} 0, ${windowDimentions.w} 0`;

  const animation = {
    exit: {
      d: exitPath,
      transition: { duration: 0.8 },
    },
  };

  return (
        <motion.div
          exit={{
            y: "-100vh",
            transition: { duration: 0.8 },
          }}
          className="preloader"
        >
          <div className="inner">
            <h1>Chargement ...</h1>
          </div>

          <svg style={{ overflow: "visible", width: "100%" }} className="curve">
            <motion.path d={initialPath} variants={animation} exit="exit" />
          </svg>
        </motion.div>
  );
}
