import React from "react";
import { LinkedIn } from "../assets/icons";
import "../styles/hero.css";

export default function Hero() {
  return (
    <main className="hero" id="hero">
      <div className="hero-content">
        <h1>ATECH</h1>
        <h3>Ingénierie & Services</h3>
        <p>
          Nous sommes votre partenaire de confiance pour concrétiser vos projets
          les plus ambitieux.
        </p>

        <div className="hero-links">
          <a href="#footer" target="_self" className="project">
            J'ai un projet
          </a>
          <a href="#" target="_blank" className="linkedin">
            <LinkedIn />
          </a>
        </div>
      </div>

      <div className="img-holder"></div>

      <img src="/imgs/hero.jpeg" alt="hero-img" />

      <div className="scroll-sign">
        <p>Scroll</p>
        <div></div>
      </div>
    </main>
  );
}
