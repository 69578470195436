import React from "react";
import "../styles/footer.css";
import { ArrowTopRight } from "../assets/icons";

export default function Footer() {
  return (
    <footer id="footer">
      <div className="builging-shape"></div>
      <div className="footer-body">
        <div className="footer-top">
          <div className="footer-top-left">
            <div className="company-name">
              <h2>ATECH</h2>
              <p>INGÉNIERIE & SERVICES</p>
            </div>

            <div className="company-links">
              <a href="tel:+212 537-895-552" target="_blank">
                <span>
                  +212 537-895-552 <ArrowTopRight />
                </span>
              </a>
              {/* <a href="tel:+33 (0) 632-211-898" target="_blank">
                <span>
                  +33 (0) 632-211-898 <ArrowTopRight />
                </span>
              </a> */}
              <a href="" target="_blank">
                <span>
                  linkedin <ArrowTopRight />
                </span>
              </a>
            </div>
          </div>
          <div className="footer-top-right">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52893.813750778085!2d-6.857667651367159!3d34.047376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda76b1e3763ec85%3A0x7c7f2e92d6cb1f96!2sA.TECH!5e0!3m2!1sfr!2sma!4v1708180120622!5m2!1sfr!2sma"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
        <div className="footer-credit">
          <p>Copyright © 2024 Atech Ingénierie</p>
        </div>
      </div>
    </footer>
  );
}
