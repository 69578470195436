import React, { useState } from "react";
import { Menu, Xmark } from "../assets/icons";
import "../styles/header.css";

export default function Header() {
  const [isActive, setIsActive] = useState(false);

  // const navLinks = [
  //   { slug: "ACCUEIL", link: "#" },
  //   { slug: "ÉCHAFAUDAGE ÉTAIEMENT", link: "#" },
  //   { slug: "BET STRUCTURE", link: "#" },
  //   { slug: "AMO ET MOE", link: "#" },
  // { slug: "CONTACT", link: "#" },
  // ];

  const navLinks = [
    { slug: "ACCUEIL", link: "#" },
    { slug: "SERVICES", link: "#service" },
    { slug: "A PROPOS", link: "#a_propos" },
    { slug: "PROCESSUS", link: "#processus" },
    { slug: "COLLABORATEURS", link: "#collaborateurs" },
    { slug: "CONTACT", link: "#footer" },
  ];

  return (
    <header className="header">
      <div className="left-part">
        <div>
          <a href="#" target="_self">
            <img src="/imgs/logo.png" alt="logo" />
          </a>
        </div>

        <button
          className="nav-button"
          onClick={() => setIsActive((prev) => !prev)}
        >
          {isActive ? <Xmark /> : <Menu />}
        </button>
      </div>
      <div className="right-part" data-open={isActive}>
        {navLinks.map(({ slug, link }, index) => (
          <a href={link} key={index} target="_self" className="nav-link">
            {slug}
          </a>
        ))}
      </div>
    </header>
  );
}
