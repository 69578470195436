import React from "react";
import "../styles/about.css";

export default function About() {
  return (
    <main className="about" id="a_propos">
      <div className="left-part">
        <img src="/imgs/about_1.jpg" alt="about" />
        <img src="/imgs/about_2.jpg" alt="about" />
      </div>
      <div className="right-part">
        <h1 className="about-line">
          Découvrez la société <span>Atech</span>
        </h1>

        <p className="p_1">
          ATECH est une entreprise créée en septembre 2018, spécialisée dans
          l'ingénierie et les études techniques de projets de construction et de
          rénovation dans les secteurs industriels et du bâtiment, en
          échafaudages et charpente métallique. Nos ingénieurs et techniciens
          disposent des moyens et du savoir-faire nécessaires, pour que nos
          clients soient satisfaits de notre prestation et du résultat final.
          Nous concevons et développons des solutions techniques qui permettent
          aux clients d'améliorer leurs performances opérationnelles. Notre
          niveau d'expertise acquis au travers de nos expériences dans de grands
          projets nationaux et internationaux, nous a permis de développer des
          compétences et un savoir-faire unique.
        </p>
        <p className="p_2">
          Notre équipe est en mesure d'assister nos clients en phases de
          conception, de préparation, de démarrage et du suivi du projet.
        </p>
      </div>
    </main>
  );
}
