import React from "react";
import ProcessItem from "./UI/ProcessItem";
import Button from "./UI/Button";
import "../styles/process.css";

export default function Process() {
  const proccesSteps = [
    {
      title: "Cadrage des besoins",
      description: [
        "Visite des lieux",
        "Analyse des besoins",
        "Validation des cahiers des charges",
      ],
      img: "list.png",
    },
    {
      title: "Analyse de l'environnement",
      description: ["Diagnostic", "Analyse des risques"],
      img: "navigator.png",
    },
    {
      title: "Relevés de côtes",
      description: [
        "Enquête du terrain",
        "Relevés des dimensions de l'existant",
        "Modélisation 2D/3D de l'existant",
      ],
      img: "tools.png",
    },
    {
      title: "Etude technique",
      description: [
        "Établissement des plans projet",
        "Rédaction des documents techniques",
        "Suivi technique jusqu'à réception",
      ],
      img: "presentation.png",
    },
    {
      title: "Gestion du projet",
      description: [
        "Encadrement et assistance technique",
        "Contrôle des coûts",
        "Suivi de travaux jusqu'au parfait achèvement",
      ],
      img: "time.png",
    },
  ];
  return (
    <main className="process" id="processus">
      <span>
        <h1>Notre processus</h1>
        <p>Projets de construction secteur privé et public</p>

        <div className="process-body">
          {proccesSteps.map((step, index) => (
            <ProcessItem key={index} step={step} />
          ))}
        </div>
      </span>

      <Button
        className="projet-button"
        content="J'ai un projet"
        href="#footer"
      />
    </main>
  );
}
